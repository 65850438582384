<header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-7 col-sm-9 col-md-7 col-lg-7 col-xl-7 col-xxl-8">
          <div class="logo_block"><a href="https://www.amsn.org/" target="_blank" class="logo_a"><img src="../assets/images/logo.png" alt=""
            class="img-fluid" /></a>
            <span><span class="qa_testing_block">Beta Version</span></span>
          </div>
        </div>
        <div class="col-5 col-sm-3 col-md-5 col-lg-5 col-xl-5 col-xxl-4">
          <a href="https://myassessments.amsn.org/" target="_blank" class="all_btn theme_btn">Your Assessment Tools</a>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </header>